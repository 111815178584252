@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.brk-forum-author-card{
  box-shadow: 0 5px 14px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  position: relative;
  &__img-container{
    width: 140px;
    height: 140px;
    border-radius: 100%;
    box-shadow: 0 0 0 1px #ececec;
    border: 3px solid #ffffff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -80px;
    overflow: hidden; 

  }
  &__img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  &__about{
    box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.03);
    background-color: #fbfbfb;
  }
}